import React from 'react'

import Layout from 'components/Layout'

import { Hero, Benefits, Contact, FAQs } from 'components/Sections'

const Page = () => (
  <Layout>
    <Hero />
    <Benefits />
    <FAQs />
    <Contact />
  </Layout>
)

export default Page
